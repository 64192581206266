import { useTranslation } from "react-i18next";

import Layout from "../layouts/index";
import Route from "../routes/index";
import { setPageBreadcrumb } from "@/utils/breadcrumb";
import { AppContextProvider } from "@/contexts/AppContext";

const App = () => {
  const { t } = useTranslation();
  setPageBreadcrumb({
    breadcrumbname: t("general.dataPolicy"),
    preview: true,
    comingsoon: false,
  });

  return (
    <AppContextProvider>
      <Layout>
        <Route />
      </Layout>
    </AppContextProvider>
  );
};

export default App;
