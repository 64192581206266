import { useMemo } from "react";

interface BreadcrumbProps {
  breadcrumbname: string;
  preview: boolean;
  comingsoon: boolean;
}

export const setPageBreadcrumb = ({
  breadcrumbname = "",
  preview = false,
  comingsoon = false,
}: BreadcrumbProps): void => {
  const breadCrumbs = useMemo(
    () => [
      {
        breadcrumbname: breadcrumbname,
        preview: preview,
        comingsoon: comingsoon,
      },
    ],
    [breadcrumbname, preview, comingsoon],
  );

  if (window.top?.APP?.$eventBus?.$emit) {
    window.top.APP.$eventBus.$emit("changeBreadCrumbs", breadCrumbs);
  }
};
